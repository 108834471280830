.snFooterContainer {
  //background-color: $link-color;
}

.nav-container {
  background-color: $primary-color;
}

.team-name-container {
  background-color: #939598;
}

yieldPageContent .textBlockElement h3 {
  border-bottom: 3px solid $secondary-color;

}